import axios from 'axios';

const API = 'leechero';

const ENDPOINTS = {
    getDataLeechero(){
        return axios.get(`${API}/info`)
    },
    getDataNewLeechero(params){
        return axios.get(`${API}/nuevo-info`, { params })
    },
    getListLeechero(){
        return axios.get(`${API}/list`)
    },
    leecheroDefault(){
        return axios.get(`${API}/predefinido`)
    },
    deleteLeechero(id_tienda){
        return axios.put(`${API}/delete/${id_tienda}`)
    },   
    cambiarLeecheroAsignado(payload){
        return axios.put(`${API}/user/cambio`, payload)
    },
    buscarLeechero(params){
        return axios.get(`${API}/codigo`, { params })
    },
    agregarLeechero(params){
        return axios.get(`${API}/nuevo`, { params })
    },     

};

export default ENDPOINTS;